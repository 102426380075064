<template>
  <list-form-styles>
    <b-modal
      ref="formModal"
      v-model="visible"
      size="md"
      title="Dictionary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      modal-class="dictionary-list-form-modal"
    >
      <div
        class="d-flex align-items-center mb-1"
      >
        <dictionary-audio-player
          v-if="dictionary.audio"
          class="mr-1"
          :dictionary="dictionary"
        />
        <dictionary-audio-upload
          :dictionary="dictionary"
          @uploaded="dictionaryAudioUploaded"
        />
      </div>

      <div class="mb-1">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4
            @dblclick="() => deleteDictionary(dictionary.id)"
          >
            {{ dictionary.title }}
          </h4>
          <div class="text-right text-muted small">
            {{ dictionary.transcription }}
          </div>
        </div>

        <hr class="my-2">

        <div
          v-for="dictPartOfSpeech of dictionary.parts_of_speech"
          :key="dictPartOfSpeech.id"
        >

          <div
            class="mb-1"
          >
            <b
              @dblclick="() => deleteDictionaryPartOfSpeech(dictPartOfSpeech.id)"
            >
              {{ dictPartOfSpeech.part_of_speech }}
            </b>
          </div>

          <table class="table table-sm">
            <tr
              v-for="dictTranslate of dictPartOfSpeech.translates"
              :key="dictTranslate.id"
              class="mb-1"
            >
              <td class="w-100">
                <span
                  @dblclick="() => deleteDictionaryTranslate(dictTranslate.id)"
                >
                  {{ dictTranslate.translate }}
                </span>
                <div v-if="false">
                  <div
                    v-for="lessonDictionary of dictTranslate.lesson_dictionaries"
                    :key="lessonDictionary.lesson.id"
                  >
                    <span class="text-muted mr-1">&mdash;</span> #{{ lessonDictionary.lesson.number }}
                    {{ lessonDictionary.lesson.title }}
                    <!--{{ lessonDictionary.verb_time }}-->
                    <!--{{ lessonDictionary.use_in_grammar_test ? 'Yes' : 'No' }}-->
                    <!--{{ lessonDictionary.use_in_translate_test ? 'Yes' : 'No' }}-->
                  </div>
                </div>
              </td>
              <td>
                <dictionary-translate-image :dictionary-translate="dictTranslate" />
              </td>
              <td>
                <dictionary-translate-image-upload
                  style="white-space: nowrap;"
                  :dictionary-translate="dictTranslate"
                  @uploaded="dictionaryTranslateImageUploaded"
                />
              </td>
            </tr>
          </table>

          <template v-if="addTranslateForm.visible && addTranslateForm.partOfSpeechId === dictPartOfSpeech.id">
            <b-form-input
              v-model="addTranslateForm.translate"
              :disabled="requestLoading"
              placeholder="Translate"
              class="mb-1"
              @keyup.esc="hideAddTranslateForm"
              @keyup.enter="addTranslate"
            />
            <div>
              <b-button
                size="sm"
                variant="primary"
                class="mr-05"
                :disabled="requestLoading"
                @click="addTranslate"
              >
                Save
              </b-button>
              <b-button
                size="sm"
                variant="outline-secondary"
                :disabled="requestLoading"
                @click="hideAddTranslateForm"
              >
                Cancel
              </b-button>
            </div>
          </template>

          <b-button
            v-else
            size="sm"
            variant="primary"
            :disabled="requestLoading"
            @click="showAddTranslateForm(dictPartOfSpeech.id)"
          >
            Add translate
          </b-button>

          <div>{{ dictPartOfSpeech.word_forms }}</div>

          <hr class="my-2">
        </div>

        <!--Add part of speech -->
        <template v-if="addPartOfSpeechForm.visible">
          <b-form-select
            v-model="addPartOfSpeechForm.partOfSpeech"
            :disabled="requestLoading"
            class="mb-1"
            aria-label="Part of speech"
            :options="availableCreatePartsOfSpeech"
            value-field="value"
            text-field="value"
          />
          <div>
            <b-button
              size="sm"
              variant="primary"
              class="mr-05"
              :disabled="requestLoading"
              @click="addPartOfSpeech"
            >
              Save
            </b-button>
            <b-button
              size="sm"
              variant="outline-secondary"
              :disabled="requestLoading"
              @click="hideAddPartOfSpeechForm"
            >
              Cancel
            </b-button>
          </div>
        </template>
        <b-button
          v-else
          size="sm"
          variant="primary"
          :disabled="requestLoading"
          @click="showAddPartOfSpeechForm"
        >
          Add part of speech
        </b-button>
      </div>

      <template #modal-footer>
        <span
          size="sm"
          class="close-btn position-absolute position-top-0 position-right-0 cursor-pointer"
          @click="hide"
        >
          <feather-icon icon="XIcon" />
        </span>

        <b-button
          v-if="false"
          size="sm"
          variant="outline-danger"
          :disabled="requestLoading"
          @click="itemDelete"
        >
          <feather-icon icon="Trash2Icon" /> Delete
        </b-button>

        <b-button
          size="sm"
          variant="outline-secondary"
          :disabled="requestLoading"
          @click="hide"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </list-form-styles>
</template>

<script>
import get from 'lodash/get'
import {
  BFormInput,
  BButton,
  BModal, BFormSelect,
} from 'bootstrap-vue'
import DictionaryAudioPlayer from '@/views/Dictionary/Components/Audio/DictionaryAudioPlayer.vue'
import DictionaryAudioUpload from '@/views/Dictionary/Components/Audio/DictionaryAudioUpload.vue'
import DictionaryTranslateImage from '@/views/Dictionary/Components/Image/DictionaryTranslateImage.vue'
import DictionaryTranslateImageUpload from '@/views/Dictionary/Components/Image/DictionaryTranslateImageUpload.vue'
import toast from '@/mixins/toast'
import { ListFormStyles } from '../../styled'

export default {
  components: {
    BFormSelect,
    DictionaryTranslateImage,
    DictionaryTranslateImageUpload,
    BFormInput,
    BButton,
    BModal,
    DictionaryAudioUpload,
    DictionaryAudioPlayer,
    ListFormStyles,
  },
  mixins: [
    toast,
  ],
  props: {
    nativeLanguage: {
      type: String,
      required: true,
    },
    foreignLanguage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dictionary: {},
    visible: false,
    requestLoading: false,
    addTranslateForm: {
      visible: false,
      partOfSpeechId: null,
      translate: null,
    },
    addPartOfSpeechForm: {
      visible: false,
      partOfSpeech: null,
      wordWorms: [],
    },
  }),
  computed: {
    formFieldErrors() {
      return field => {
        const errors = get(this.formErrors, field, [])
        return Array.isArray(errors) ? errors : []
      }
    },
    availableCreatePartsOfSpeech() {
      const partsOfSpeech = [
        'noun',
        'pronoun',
        'verb',
        'adjective',
        'adverb',
        'preposition',
        'conjunction',
        'interjection',
        'numeral',
        'article',
        'determiner',
      ]
      return partsOfSpeech
        .filter(partOfSpeech => !this.dictionary.parts_of_speech.map(_ => _.part_of_speech).includes(partOfSpeech))
        .map(partOfSpeech => ({ value: partOfSpeech }))
    },
  },
  methods: {
    show(item) {
      this.dictionary = item
      this.visible = true
    },
    async reloadDictionary(id) {
      const response = await this.$http.get(
        `${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/${id}`,
      )
      this.dictionary = response.data.item
    },
    hide() {
      this.visible = false
      this.hideAddTranslateForm()
      this.hideAddPartOfSpeechForm()
    },
    showAddTranslateForm(partOfSpeechId) {
      this.addTranslateForm.visible = true
      this.addTranslateForm.partOfSpeechId = partOfSpeechId
      this.addTranslateForm.translate = null
    },
    hideAddTranslateForm() {
      this.addTranslateForm.visible = false
    },
    async addTranslate() {
      this.requestLoading = true
      try {
        const formData = new FormData()
        formData.append('dictionary_part_of_speech_id', this.addTranslateForm.partOfSpeechId)
        formData.append('translate', this.addTranslateForm.translate)
        const response = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/translate/create`,
          formData,
        )

        const partOfSpeech = this.dictionary.parts_of_speech.find(_ => _.id === this.addTranslateForm.partOfSpeechId)
        partOfSpeech.translates.push(get(response, 'data.translate'))
        this.hideAddTranslateForm()
        this.$toastDefault('Success', 'Add translate')
      } catch (e) {
        this.$toastError(get(e, 'response.data.message', 'Error'), 'Add translate')
      }
      this.requestLoading = false
    },
    showAddPartOfSpeechForm() {
      this.addPartOfSpeechForm.visible = true
      this.addPartOfSpeechForm.partOfSpeech = null
      this.addPartOfSpeechForm.wordWorms = []
    },
    hideAddPartOfSpeechForm() {
      this.addPartOfSpeechForm.visible = false
    },
    async addPartOfSpeech() {
      this.requestLoading = true
      try {
        const formData = new FormData()
        formData.append('dictionary_id', this.dictionary.id)
        formData.append('part_of_speech', this.addPartOfSpeechForm.partOfSpeech)
        formData.append('word_forms', this.addPartOfSpeechForm.wordWorms)
        const response = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/part-of-speech/create`,
          formData,
        )

        this.dictionary.parts_of_speech.push(get(response, 'data.part_of_speech'))
        this.hideAddPartOfSpeechForm()
        this.$toastDefault('Success', 'Add part of speech')
      } catch (e) {
        this.$toastError(get(e, 'response.data.message', 'Error'), 'Add part of speech')
      }
      this.requestLoading = false
    },
    itemDelete() {
      if (window.confirm('Are you sure you want to delete?')) {
        this.requestLoading = true
        this.$store.dispatch('dictionary/delete', { id: this.dictionary.id })
          .then(() => {
            this.$emit('deleted')
            this.hide()
          })
          .catch(error => {
            window.alert(get(error, 'response.data.message', 'error'))
          }).finally(() => {
            this.requestLoading = false
          })
      }
    },
    dictionaryAudioUploaded() {
      this.reloadDictionary(this.dictionary.id)
    },
    dictionaryTranslateImageUploaded() {
      this.reloadDictionary(this.dictionary.id)
    },

    async deleteDictionary(id) {
      if (window.confirm('Delete dictionary?')) {
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/${id}`)
          .then(() => this.reloadDictionary(this.dictionary.id))
      }
    },
    async deleteDictionaryPartOfSpeech(id) {
      if (window.confirm('Delete part of speech?')) {
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/part-of-speech/${id}`)
          .then(() => this.reloadDictionary(this.dictionary.id))
      }
    },
    async deleteDictionaryTranslate(id) {
      if (window.confirm('Delete translate?')) {
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/api/v1/admin/dictionary/translate/${id}`)
          .then(() => this.reloadDictionary(this.dictionary.id))
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
