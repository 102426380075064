<template>
  <div class="dictionary-list-page">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2 class="mb-0">
          Dictionaries
        </h2>
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
          variant="flat-primary"
          :text="nativeLangByCode(nativeLanguage).title"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="lang in langThree"
            :key="lang.code"
            @click="setNativeLanguageCode(lang.code)"
          >
            {{ lang.title }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="flat-primary"
          :text="foreignLanguageByCode(nativeLanguage, foreignLanguage).title"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="lang in nativeLangByCode(nativeLanguage).foreign_languages"
            :key="lang.code"
            @click="setForeignLanguageCode(lang.code)"
          >
            {{ lang.title }}
          </b-dropdown-item>
        </b-dropdown>

        <b-form-checkbox
          v-model="filterWithoutAudio"
          class="mr-2 text-nowrap"
        >
          Without audio
        </b-form-checkbox>

        <b-form-input
          v-model="search"
          class="mr-2"
          placeholder="Search"
          @keypress.enter="onSearchEnter"
        />
        <b-button
          v-b-modal.create-lesson-modal
          variant="primary"
          @click="$refs.dictionaryCreateForm.show()"
        >
          Create
        </b-button>
      </div>
    </div>
    <b-table
      ref="table"
      striped
      hover
      :current-page="page"
      :per-page="perPage"
      :fields="lessonsFields"
      :items="itemsProvider"
    >
      <template #cell(right)="data">
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="$refs.dictionaryForm.show(data.item)"
        >
          <feather-icon icon="EyeIcon" /> Show
        </b-button>
      </template>
    </b-table>

    <div class="d-flex justify-content-between align-items-center">
      <b-pagination
        v-model="page"
        :total-rows="totalItems"
        :per-page="perPage"
      />
      <div>
        <span class="text-muted mr-1">
          per page
        </span>
        <b-dropdown
          variant="flat-primary"
          :text="`${perPage === 0 ? 'All' : perPage}`"
          class="mr-2"
        >
          <b-dropdown-item
            v-for="(item, i) in pageItems"
            v-show="!item.hidden"
            :key="i"
            @click="perPage = item.per"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <dictionary-form
      ref="dictionaryForm"
      :native-language="nativeLanguage"
      :foreign-language="foreignLanguage"
      @deleted="formOnDeleted"
    />

    <dictionary-create-form
      ref="dictionaryCreateForm"
      :native-language="nativeLanguage"
      :foreign-language="foreignLanguage"
      @created="formOnCreated"
    />
  </div>
</template>

<script>
import {
  BButton, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BPagination, BTable,
} from 'bootstrap-vue'
import DictionaryForm from '@/views/Dictionary/List/Components/DictionaryForm.vue'
import DictionaryCreateForm from '@/views/Dictionary/List/Components/DictionaryCreateForm.vue'
import upperFirst from 'lodash/upperFirst'
import toast from '@/mixins/toast'
import store from '@/store/index'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    DictionaryForm,
    DictionaryCreateForm,
  },
  mixins: [
    toast,
  ],
  data: () => ({
    page: 1,
    perPage: 100,
    totalItems: 0,
    search: null,
    createFormErrors: {},
    createFormErrorMessage: null,
    nativeLanguage: 'ru',
    foreignLanguage: 'en',
    filterWithoutAudio: false,
    pageItems: [
      { name: 'All', per: 0, hidden: true },
      { name: '10', per: 10 },
      { name: '25', per: 25 },
      { name: '50', per: 50 },
      { name: '100', per: 100 },
      { name: '250', per: 250 },
    ],
  }),
  computed: {
    ...mapState({
      total: state => state.dictionary.total,
      dictionaries: state => state.dictionary.dictionaries,
    }),
    langThree() {
      return store.state.app.langThree
    },
    nativeLangByCode() {
      return nativeLang => this.langThree.find(lang => lang.code === nativeLang) || {
        code: '',
        title: '',
        default: false,
        foreign_languages: [],
      }
    },
    foreignLanguageByCode() {
      return (nativeLang, foreignLang) => this.nativeLangByCode(nativeLang).foreign_languages.find(lang => lang.code === foreignLang) || {
        code: '',
        title: '',
        default: false,
      }
    },
    upperFirst() {
      return upperFirst
    },
    lessonsFields() {
      return [
        {
          key: 'title', label: 'Title', sortable: true, class: 'cell-title',
        },
        {
          key: 'has_audio', label: 'Audio', sortable: false, class: 'text-center',
        },
        {
          key: 'right', label: '', sortable: false, class: 'cell-right',
        },
      ]
    },
  },
  watch: {
    filterWithoutAudio() {
      this.page = 1
      this.totalItems = 0
      this.$refs.table.refresh()
    },
  },
  created() {
    if (this.$route.query.native_lang) {
      this.nativeLanguage = this.$route.query.native_lang
    }

    if (this.$route.query.foreign_lang) {
      this.foreignLanguage = this.$route.query.foreign_lang
    }

    this.$store.subscribe((mutation, state) => {
      if (mutation.type.includes('UPDATE_DICTIONARY')) {
        this.formOnUpdated()
      }
      if (mutation.type.includes('DELETE_DICTIONARY')) {
        this.formOnDeleted()
      }
      if (mutation.type.includes('CREATE_DICTIONARY')) {
        this.formOnCreated()
      }
    })
  },
  methods: {
    // Maping dictionary load action as method
    ...mapActions({ loadDictionaries: 'dictionary/loadList' }),
    setNativeLanguageCode(code) {
      this.nativeLanguage = code
      const currentForeignLanguage = this.nativeLangByCode(code).foreign_languages.find(lang => lang.code === this.foreignLanguage)
      const defaultForeignLanguage = this.nativeLangByCode(code).foreign_languages.find(lang => lang.default) || {}
      this.foreignLanguage = currentForeignLanguage ? currentForeignLanguage.code : defaultForeignLanguage.code
      this.changeLanguage()
    },
    setForeignLanguageCode(code) {
      this.foreignLanguage = code
      this.changeLanguage()
    },
    changeLanguage() {
      this.$router.replace({
        path: '/dictionary/list',
        query: {
          native_lang: this.nativeLanguage,
          foreign_lang: this.foreignLanguage,
        },
      })
      window.location.reload()
    },
    itemsProvider(ctx, callback) {
      const params = {
        count_usage: 1,
        page: ctx.currentPage,
        per_page: ctx.perPage,
        native_lang: this.nativeLanguage,
        foreign_lang: this.foreignLanguage,
      }
      if (this.search !== null && this.search !== '') {
        params.search = this.search
      }
      if (this.filterWithoutAudio) {
        params.has_audio = 0
      }
      if (ctx.sortBy) {
        params.order_by = ctx.sortBy
        params.order_dir = ctx.sortDesc ? 'desc' : 'asc'
      }
      this.loadDictionaries(params)
        .then(response => {
          response.data.items.map(item => {
            item.has_audio = item.audio ? '🔈' : '' // eslint-disable-line no-param-reassign
            return item
          })
          callback(response.data.items)
          if (!this.totalItems) {
            this.totalItems = response.data.total
          }
        })
        .catch(() => {
          callback([])
          this.$toastError('Error', 'Load dictionary')
        })
      return null
    },
    formOnCreated() {
      this.page = 1
      this.totalItems = 0
      this.$refs.table.refresh()
    },
    formOnDeleted() {
      this.page = 1
      this.totalItems = 0
      this.$refs.table.refresh()
    },
    onSearchEnter() {
      this.page = 1
      this.totalItems = 0
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/base/pages/page-dictionary';
</style>
